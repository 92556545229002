footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff; /* Match the same background color as card/form */
    padding: 20px;
    border-top: 1px solid #ddd;
    margin-top: 30px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .newsletter-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .newsletter-form {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .newsletter-form input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
    font-size: 1em;
    flex-grow: 1;
  }
  
  .newsletter-form input:focus {
    border-color: rgba(255, 68, 0, 0.952); /* Highlight color on focus */
  }
  
  .newsletter-form button {
    padding: 10px 20px;
    border: none;
    background-color: rgba(255, 68, 0, 0.952); /* Matching button color */
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .newsletter-form button:hover {
    background-color: #ff4500; /* Slightly darker shade on hover */
  }
  
  .footer-line {
    width: 100%;
    margin: 20px 0;
    border: none;
    border-top: 1px solid #ddd; /* Line to separate sections */
  }
  
  footer p {
    font-size: 0.9em;
    color: #555;
    text-align: center;
    margin: 0;
  }
  