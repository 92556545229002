.cart-page {
    /* width: 100%; */
    margin: 0 auto;
  }
  
  .cart-container {
    margin: 0 auto;
  }
  
  .cart-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
  }
  
  .cart-item-image {
    width: 100px;
    height: auto;
    margin-right: 20px;
  }
  
  .cart-item-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .cart-item-quantity {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .cart-item-quantity button {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: white;
  }
  
  .remove-item-btn {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
  }
  