/* Card Container */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 240px;
  margin: 20px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  /* border: 1px solid black; */
  /* height: 400px; */
}

/* Card Hover Effect */
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Card Image Container */
.card-image-container {
  position: relative;
  width: 100%;
  height: 200px; /* Fixed height for consistent layout */
  overflow: hidden;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
}

.card:hover img {
  opacity: 0.9;
}

/* Image Placeholder */
.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: bold;
}

/* Card Text */
.card-text {
  text-align: left;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text h5 {
  font-size: 1.2em;
  margin: 8px;
  color: #333;
  font-family: 'Arial', sans-serif;
  transition: color 0.2s, text-decoration 0.2s;
}

.card:hover h5 {
  text-decoration: underline;
}

/* Card Price */
.card-price {
  color: #ff4400;
  font-size: 1.3em;
  font-weight: bold;
  margin: 8px;
  font-family: 'Arial', sans-serif;
}

/* Responsive Design */
@media (max-width: 600px) {
  .card {
    width: 90%;
    margin: 10px auto;
    height: auto; /* Allow flexibility for smaller screens */
  }

  .card-image-container {
    height: 160px;
  }
}
