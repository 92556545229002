/* Selected Product Page Styles */
.selected-product-container {
  background-color: #f9f9f9;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  gap: 1em;
  width: 100%;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: white;
  border-radius: 4px;
  padding: 1.5em;
  width: 100%;
  max-width: 1200px;
  gap: 1.5em;
  box-sizing: border-box;
}

.product-image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  min-width: 350px;
}

.product-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* Ensure image doesn't overflow */
}

.product-price {
  font-size: 1.4em;
  color: #f57c00;
  font-weight: bold;
  margin-top: 0.5em;
}

.product-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  max-width: 450px; /* Ensure form doesn't stretch too wide */
  box-sizing: border-box;
}

.product-info h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #333;
}

.product-description {
  font-size: 1em;
  color: #666;
  margin-top: 1em;
  word-wrap: break-word; /* Ensure long text doesn't cause overflow */
}

.read-more-btn {
  background-color: transparent;
  border: none;
  color: #f57c00;
  cursor: pointer;
  font-size: 1em;
  text-decoration: underline;
}

.related-products h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 1em;
  text-align: center;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  box-sizing: border-box;
}

.products-list .card {
  max-width: 200px;
  box-sizing: border-box;
  margin-bottom: 1em;
  flex: 1 1 auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .product-page {
    padding: 1em;
  }

  .product-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em;
  }

  .product-image {
    width: 70%;
    align-items: center;
  }

  .product-form-container {
    width: 100%;
    max-width: 600px; /* Prevent form from becoming too wide */
  }

  .products-list {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .product-image {
    width: 80%;
  }

  .product-info h1 {
    font-size: 1.8em;
  }

  .product-price {
    font-size: 1.3em;
  }

  .product-description {
    font-size: 0.95em;
  }

  .product-page {
    padding: 0.5em;
  }

  .product-container {
    padding: .5em;
  }
}

@media (max-width: 480px) {

  .product-page {
    padding: 0em;
  }

  .product-image {
    width: 100%;
  }

  .product-info h1 {
    font-size: 1.6em;
  }

  .product-price {
    font-size: 1.2em;
  }

  .product-description {
    font-size: 0.9em;
  }

  .products-list .card {
    flex: 1 1 100%;
    margin-bottom: 0.5em;
  }
}
