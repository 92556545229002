.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #666;
  }
  
  .btn {
    padding: 1rem 2rem;
    background-color: Orange;
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  